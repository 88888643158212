$dark: #10132a;
$light: #ffffff;
$light001: #ccd6f6;
$light002: #aebedb;
$light005: #9da6c4;
$light006: #5e7191;
$light007: #7883a8;
$accent: #89fdea;
$dark001: #112240;
$dark002: #132248;
$dark003: #1c2c55;

$mobileWidth: 480px;
$tabWidth: 750px;
$themeWidth: 1000px;
