@use "variables";

.experienceContainer {
  // background-color: rgba(128, 128, 128, 0.148);
  max-width: 700px;
  padding: 100px 0px;
  margin: 0px auto;

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Set the background color of the track */
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent; /* Set the color of the thumb */
  }
}
.experienceTitle {
  user-select: none;
  padding-top: 40px;
  font-size: 32px;
  color: variables.$light001;
  margin-bottom: 70px;
}

.innerContainerExp {
  // padding-top: 40px;
  display: flex;
  flex-direction: row;
}
.expSideMenuItemname {
  user-select: none;
  height: 50px;
  min-width: 150px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: variables.$light005;
  font-weight: 300;
  border-left: 3px solid #162849;
}
.expSideMenuItemname:hover {
  cursor: pointer;
  background-color: #16284977;
  color: variables.$accent;
}

.expItemSelected {
  background-color: #162849;
  color: variables.$accent;
}

.sideListUl {
  position: relative;
}
.sideScrollExp {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  width: 2px;
  height: var(--tab-height);
  background: variables.$accent;
  transform: translateY(calc(var(--exp-item-index) * var(--tab-height)));
  transition: transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
}

.experienceDetail {
  padding-left: 20px;
  width: 100%;
  #expPanel {
    height: 400px;
    h3 {
      line-height: 2;
      font-weight: 600;
      font-size: 22px;
      color: variables.$light001;

      .company {
        font-weight: 600;
        font-size: 22px;
        color: variables.$accent;
      }
      .inlineLink {
        font-weight: 600;
        font-size: 22px;
        color: variables.$accent;
        transition: all 0.3s ease; /* Adding a smooth transition effect */
      }
    }

    .range {
      color: variables.$light002;
      margin-bottom: 25px;
    }
    .expPointsUl {
      li {
        position: relative;
        padding-left: 30px;
        margin-bottom: 10px;
        color: #7f8aad;
        font-weight: 300;
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 0.6px;
      }
      .expPointListItem::before {
        content: ""; /* Required to generate the pseudo-element */
        display: flex; /* Make it a block-level element to occupy space */
        width: 10px; /* Set the width of the pseudo-element */
        height: 10px; /* Set the height of the pseudo-element */
        background-image: url("../assets/svg/BulletPoint.svg"); /* URL to your SVG */
        background-size: cover; /* Adjust the background size */
        margin-right: 10px; /* Add space to the right of the pseudo-element */
        position: absolute;
        left: 0px;
        top: 8px;
      }
    }
  }
}

.fadeInExperienceSection {
  animation: fadeIn 100ms ease-in-out 0s forwards;
}

.fadeOutExperienceSection {
  animation: fadeOut 100ms ease-in-out 0s forwards;
}

.displayNone {
  display: none;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translate(0px);
  }
  100% {
    opacity: 0;
    transform: translate(20px);
    // display: none; /* Hide the element after animation */
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(20px);
  }
  100% {
    opacity: 1;
    transform: translate(0px);
    // display: none; /* Hide the element after animation */
  }
}

@media (orientation: portrait), (max-width: 500px) {
  .experienceContainer {
    margin-bottom: 300px;
  }
}

@media (orientation: portrait), (max-width: variables.$tabWidth) {
  .experienceContainer {
    margin-bottom: 200px;
  }
  .innerContainerExp {
    flex-direction: column;
    .experienceDetail {
      padding-top: 50px;
    }
    .sideList {
      // margin-left: -10px;
      display: flex;
      overflow-x: auto;
      height: 70px;
      // width: calc(100%);
      // padding-left: 50px;
      // margin-left: -50px;
      // margin-bottom: 30px;
      .sideListUl {
        display: flex;
        flex-direction: row;
        .expSideMenuItemname {
          border-bottom: 3px solid #162849;
          border-left: none;
        }
      }
    }
  }

  .sideScrollExp {
    position: absolute;
    top: var(--tab-height);
    left: 0px;
    z-index: 10;
    height: 2px;
    width: var(--tab-width);
    background: variables.$accent;
    transform: translateX(calc(var(--exp-item-index) * var(--tab-width)));
    // transition: transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  }
}
